export const User = [
  {
    path: '/user/account',
    name: 'user-account',
    component: () => import('@/views/User/Account/Index.vue'),
    meta: {
      title: 'Account Overview',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['user.update'],
      menu: 'top',
    },
  },
  {
    path: '/user',
    redirect: {
      name: 'user-account',
    },
  },
  {
    path: '/billing-invoices',
    name: 'invoices',
    component: () => import('@/views/Invoice/Index.vue'),
    meta: {
      title: 'Billing & Invoices',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['invoices.read', 'payment_methods.read'],
      menu: 'top',
    },
  },
  {
    path: '/settings/notifications',
    name: 'notifications',
    component: () => import('@/views/User/Settings/Notifications/Index.vue'),
    meta: {
      title: 'Notifications',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['notification_settings.update', 'notification_settings.read'],
      menu: 'top',
    },
  },
  {
    path: '/settings',
    redirect: {
      name: 'user-account',
    },
  },
];
