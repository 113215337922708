import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';
import store from './store';
import globalMixin from './mixins/legacyFilters';
import { initGlobalOptions } from '@/utils/chart';
import DashboardPlugin from './plugins/dashboard-plugin';
import { getTrackingInitials } from '@/utils/index';

import './assets/css/index.css';

const pinia = createPinia();
const app = createApp(App);
app.config.productionTip = false;
app.use(DashboardPlugin, store);
app.mixin(globalMixin);
initGlobalOptions();

document.getElementById('app').addEventListener('userLoggedIn', (e) => {
  getTrackingInitials(e.detail.user);
}, { once: true });

let isClosingTab = false;

/**
 * We want to ignore errors that are caused by the user closing the tab.
 * Or refreshing the page.
 */
window.addEventListener('beforeunload', () => {
  isClosingTab = true;
});
// Sentry - bug catcher
if (import.meta.env.VITE_APP_ENV !== 'dev') {
  Sentry.init({
    Vue: app,
    release: 'portal@' + import.meta.env.VERSION,
    environment: import.meta.env.VITE_APP_ENV,
    dsn: import.meta.env.VITE_APP_SENTRY_URL,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Add the benign errors to ignore
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Illegal invocation',
      'Could not load "util".', // Google Maps
      /Unable to preload CSS for (.*)/,
      /Failed to fetch dynamically imported module: (.*)/,
    ],
    denyUrls: [/maps\.googleapis\.com/],
    beforeSend(event, hint) {
      /**
       * We want to ignore errors that are caused by the user closing the tab.
       * E.g. all requests that are aborted because the user navigates away from the page.
       * @note Returning null will cause the event to be dropped.
       */
      if (isClosingTab) return null;

      if (event && typeof event === 'object') {
        const errorName =
          event.exception &&
          event.exception.values &&
          event.exception.values.length > 0 &&
          event.exception.values[0].value;

        if (errorName === "Unexpected token '<'") {
          console.log("new version? let's try to refresh ya.");
          window.location.reload();
        }

        if (hint && hint.originalException === 'Timeout') return null;

        return event;
      }
    },

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(router);
app.use(store);
app.use(pinia);
app.mount('#app');
