import axios from 'axios';
import store from '../../../store';
import router from '../../../router';

const API_VERSION = 'v1';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

client.interceptors.request.use(
  (config) => {
    const token = store.getters['auth/getToken'];

    if (token) {
      config.headers['X-Removify-Token'] = token;
    }

    // Demo
    const isDemoMode = store.getters['user/isDemoMode'];
    const demoName = localStorage.getItem('_demoname');

    if (isDemoMode && demoName) {
      config.headers['X-Demo-Name'] = demoName;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Unauthorised User
    if (error.response?.status === 401) {
      // Check if the page is public.
      if (router.currentRoute.value.meta?.guest) {
        const win: Window = window;
        win.location = '/logout?hr=true';
      }
    }

    // Everything else (e.g. 500)
    return Promise.reject(error);
  },
);

export default client;
