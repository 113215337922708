import client from '@/services/api/RemovifyServiceV2/config.js';
import store from '@/store';

/**
 * Get the user notification settings
 * @returns {Promise}
 */
export const getUserNotificationSettings = () => {
  return client.get(
    `/users/${store.getters['user/getUserId']}/customer/${store.getters['user/getCustomerID']}/settings/notification`,
  );
};

/**
 * Update the user notification settings
 * @returns {Promise}
 */
export const updateUserNotificationSettings = (data) => {
  return client.put(
    `/users/${store.getters['user/getUserId']}/customer/${store.getters['user/getCustomerID']}/settings/notification`,
    data,
  );
};

export default {
  getUserNotificationSettings,
  updateUserNotificationSettings,
};
