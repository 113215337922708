export const Messages = [
  {
    path: '/messages',
    name: 'messages',
    component: () => import('@/views/Messages/Index.vue'),
    meta: {
      title: 'Messages',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['messages.read', 'messages.create'],
      menu: 'primary-bottom',
      icon: 'ri ri-messages',
      excludeFooter: true,
    },
  },
];
