<template>
  <div id="app" :class="page">
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
    <div id="portalModal"></div>
  </div>
</template>

<script>
const default_layout = 'Blank';
import api from '@/services';
import { useEventListener } from '@vueuse/core';
import Dashboard from '@/layouts/Dashboard.vue';
import Blank from '@/layouts/Blank.vue';

export default {
  name: 'RemovifyApp',
  components: {
    Dashboard,
    Blank,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
    page() {
      return this.$route.name || '';
    },
    version() {
      return import.meta.env.VERSION;
    },
    environment() {
      return import.meta.env.VITE_APP_ENV;
    },
  },
  created() {
    useEventListener(window, 'focus', () => {
      this.checkVersion();
    });
  },
  mounted() {
    // set DOM meta app version
    document
      .querySelector("[name='app-version']")
      .setAttribute('content', this.version);

    // set to check version every 15 minutes
    setInterval(
      () => {
        this.checkVersion();
      },
      1000 * 60 * 15,
    );
    // check version
    this.checkVersion();
  },
  methods: {
    async checkVersion() {
      try {
        const response = await api.rmvfyV2.ping();
        const apiVersion = (response && response.data) || '';

        if (this.environment === 'production' && apiVersion !== this.version) {
          console.log(
            'This app version is currently out of date. Please restart.',
          );
          const refreshContent = `
            <div class="text-center">
              <h4 class="text-blue">Refresh Portal</h4>
              <p>
                An updated version of the portal was released. Please click the below button or refresh the page
              </p>
              <button
                type="button"
                class="el-tooltip btn base-button btn-icon btn-fab btn-primary btn-lg"
                tabindex="0"
                style="border-radius: 4px;"
              >
                <div class="d-flex align-items-center">
                  <i class="ai ai-refresh ai-125x"></i>
                  <span class="btn-inner--text">Refresh</span>
                </div>
              </button>
            </div>`;

          this.$notify({
            message: refreshContent,
            /**
             * Timeout should as same as the interval time
             * @note we might see two notifications but it's fine as long as the user can see the notification
             */
            timeout: 0,
            type: 'neutral',
            verticalAlign: 'top',
            horizontalAlign: 'center',
            showClose: false,
            onClick: () => {
              /**
               * @note set to true to force hard reload but only work for Firefox
               * @see https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
               */
              window.location.reload(true);
            },
            clear: true,
          });
        }
      } catch (error) {
        let msg = 'Something went wrong. Please try again later.';
        if (error.response?.data?.errors) {
          msg = '';
          // add errors into msg
          error.response.data.errors.forEach((err) => {
            msg += err.message + '.';
          });
        } else if (error.message) {
          msg = error.message + '. Please try again later.';
        }
        this.$notify({
          message: msg,
          timeout: 3000,
          type: 'warning',
          verticalAlign: 'bottom',
          horizontalAlign: 'right',
          showClose: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

// global component hide in print
@media print {
  .notifications {
    display: none;
  }
  .sidenav {
    display: none;
  }
  #global-nav-bar {
    display: none;
  }
}
</style>
