<template>
  <span>
    $<span class="tw-tabular-nums">{{ formatNumber(amount) }}</span> <span class="tw-uppercase tw-self-center tw-text-[.75em]">{{ currency }}</span>
  </span>
</template>

<script setup lang="ts">
interface Props {
  /**
   * Currently we only support AUD and USD
   */
  currency: 'aud' | 'usd';
  /**
   * The amount to display
   */
  amount: number;
}

defineOptions({
  name: 'BaseMoney',
});

defineProps<Props>();

const formatNumber = (number: number) => {
  return new Intl.NumberFormat('en-AU', { minimumFractionDigits: 2 }).format(number);
};
</script>
