import api from '@/services';
export const namespaced = true;

export const getDefaultState = () => {
  return {
    cart: {},
    total: 0,
    notes: '',
    isLoading: false,
    toggleMenu: false,
  };
};

export const state = getDefaultState();

export const getters = {
  getOrderItems: (state) => {
    return state.cart.items;
  },
  getReviewById: state => (id) => {
    return (
      state.cart &&
      state.cart.items &&
      state.cart.items.find(item => item.review_id === id)
    );
  },
  getCartId: (state) => {
    return state.cart.id;
  },
};

export const mutations = {
  SET_CART(state, cart) {
    state.cart = cart;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_NOTES(state, notes) {
    state.notes = notes;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  SET_TOGGLE_MENU(state, bool) {
    state.toggleMenu = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  /**
   * Majority of these actions can be condensed into one as they have similarities.
   * Will leave it as-is until I have the time to revisit.
   */
  fetchCart({ commit }) {
    commit('SET_LOADING', true);
    return api.crm
      .getCustomerCart()
      .then((response) => {
        if (response.data && response.data.data) {
          commit('SET_CART', response.data.data);
          commit('SET_TOTAL', response.data.data.items.length);
          commit('SET_NOTES', response.data.data.notes);
          commit('SET_LOADING', false);
          return response.data;
        } else return response;
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        return error;
      });
  },
  submit({ commit }, data) {
    commit('SET_LOADING', true);
    return api.crm
      .submitCustomerCart(data)
      .then((response) => {
        if (response.status === 200) {
          commit('SET_LOADING', false);
          return response;
        } else throw response;
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        return error;
      });
  },
  addItem({ commit }, item) {
    commit('SET_LOADING', true);
    return api.crm
      .addItem(item)
      .then((response) => {
        if (response.data && response.data.data) {
          commit('SET_LOADING', false);
          commit('SET_CART', response.data.data.order);
          commit('SET_TOTAL', response.data.data.order.items.length);
          commit('SET_NOTES', response.data.data.order.notes);
          return response;
        } else throw response;
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        return error;
      });
  },
  removeItem({ commit }, id) {
    commit('SET_LOADING', true);
    return api.crm
      .removeItem(id)
      .then((response) => {
        if (response.data && response.data.data) {
          commit('SET_LOADING', false);
          commit('SET_CART', response.data.data.order);
          commit('SET_TOTAL', response.data.data.order.items.length);
          commit('SET_NOTES', response.data.data.order.notes);
          return response;
        } else throw response;
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        return error;
      });
  },
  addNotes({ commit }, notes) {
    return api.crm
      .addNotes(notes)
      .then((response) => {
        if (response.data && response.data.data) {
          commit('SET_CART', response.data.data);
          commit('SET_TOTAL', response.data.data.items.length);
          commit('SET_NOTES', response.data.data.notes);
        } else throw response;
      })
      .catch(error => error);
  },
  emptyCart({ commit }) {
    return api.crm
      .emptyCart()
      .then(() => {
        commit('SET_CART', {});
        commit('SET_TOTAL', 0);
        commit('SET_NOTES', '');
      })
      .catch(error => error);
  },
};
